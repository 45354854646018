import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/FCICcompany', label: 'First Chicago Insurance Company' }
]

const CompanyFCIC = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
    <>
        <title>First Chicago Insurance Company - Warrior Invictus</title>
        <Banner title="First Chicago Insurance Company" image="banner-company-sm" />
        <div className='container'>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
              </Breadcrumb>

            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill h6Font no-wrap col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>        
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" alt="first chicago logo" src={cdnLink + "/img/logos/firstchicagoinsurancecompany_leftaligned_150x72.svg"}/>                
                        <div className="h5Font">Insurance Carrier</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                        First Chicago Insurance Company (FCIC) is a member of the Warrior Insurance Network (WIN). FCIC, whose roots trace back to 1920, has evolved into a multi-line insurance carrier, now offering Personal & Commercial Auto and General Liability insurance in response to the needs of the company’s independent producers and customer base. In personal lines, FCIC has further responded to the changing exposures and needs of their independent producers by adding optional insurance coverages such as Contents Plus Renters, Roadside Assistance, and Transportation Network Coverage to enhance their personal lines auto offerings.                         
                        </p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <p className='h6Font font-secondary'>1920</p>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>      
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Licensed States</div>
                            <p className='h6Font font-secondary'>CO  IL  IN  IA  KS  LA  MD<br/> MO  NJ  NV OH  PA  TN<br/>  TX  VA  WI</p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:7085524400">708-552-4400</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_FCIC_URL}>www.firstchicagoinsurance.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyFCIC