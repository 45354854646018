import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
const items = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/Lonestarcompany', label: 'Lonestar Managing General Agency' }
]

const CompanyLonestar = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
      <>
        <title>Lonestar - Warrior Invictus</title>
        <Banner title="Lonestar Managing General Agency" image="banner-company-sm" />
        <div className='container'>
            <Breadcrumb>
                {
                    items.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill h6Font no-wrap col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>        
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" alt="lonestarmga logo" src={cdnLink + "/img/logos/lonestarmga_leftaligned_188x55.svg"} />                
                        <div className="h5Font">Managing General Agency</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                            Lonestar MGA (Lonestar) is a managing general agency of the Warrior Insurance Network (WIN), which encompasses a multitude of insurance companies and agencies. Since 1980 WIN’s member companies have been involved in underwriting and sales of auto insurance, as well as life, health, ancillary insurance through a network of various insurance carriers, and independent insurance agencies. Lonestar was created in 2007 to expand WIN’s presence to include the Texas market. 
                            <br/><br/>
                              Lonestar offers two Personal Auto Insurance Programs (Maverick & Point Rider) which are designed to provide options and flexibility within underwriting, pricing, and claims service all of which will enable insurance agencies to provide our products to consumers in the personal and coming soon commercial auto insurance markets. Lonestar’s Personal Auto Insurance Products are issued through First Chicago Insurance Company or Home State County Mutual Insurance Company. WIN’s member insurance carriers have written over $1B of insurance premium. WIN’s member insurance carriers have written over $1B of insurance premium. WIN’s management team, many of whom have over 25 years of experience in the insurance industry, oversee its daily operations and guide this growing network. The company is currently looking to further its imprint within Texas.  As such, we are currently looking for independent insurance agency partners who are both professional and consumer friendly.
                        </p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <p className='h6Font font-secondary'>2007</p>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                Richardson, Texas
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Licensed States</div>
                            <p className='h6Font font-secondary'>TX</p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:2145708358">214-570-8358</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_LS_URL}>www.lonestarmga.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyLonestar