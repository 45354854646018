import React from 'react'
import { Banner } from '../components/Banner'
import Breadcrumb from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { Card } from '../components/Cards'
import { USmap } from "../components/us-js-map";

const items = [
    { to: '/', label: 'Home' },
    { to: '/AboutUs', label: 'About Us' }
]

const AboutUs = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
      <>
        <title>About Us - Warrior Invictus</title>
        <Banner title="About US" image="banner-about-us"/>
        <div className="container pb-5">
            <Breadcrumb>
                {items.map(({to, label}) =>(           
                    <Link key={to} to={to}>{label}</Link>
                ))}
            </Breadcrumb>
            <div className="row text-center justify-content-center">
                <div className="col-lg-12 pb-5">
                    <h2>About Warrior Invictus Holding Company, Inc.</h2>
                    <h3>Warrior Invictus is an insurance holding company located in Bedford Park, Illinois. See below to see all the states we are licensed in:</h3>
                </div>
            </div>
            <div className="row align-items-center justify-content-center pb-5">
                <div className="col-lg-8 col-md-12">
                      <div>
                          {/*<img src={WINMap} className="w-100 h-100" alt="US Map" />*/}
                          <USmap/>
                    </div>
                </div>
            </div><br/><br/>
            <div className="row text-center px-2">
                <div className="col-lg-12 pb-5">
                    <h2>How We Started</h2>
                    <p className="px-2">Warrior Invictus was founded in 1980. This holding’s company is regionally recognized and respected as a leader in the insurance industry by consistently exceeding customer expectation through:</p>
                </div>
            </div>
            <div className="row card-mobile-gap px-4 justify-content-center pb-5">
                  <div className="col-lg-4"><Card size="" type="" title="EXCEPTIONAL SERVICE" image={cdnLink + "/img/exceptional-service_255x177.svg"} /></div>
                  <div className="col-lg-4"><Card size="" type="" title="INNOVATIVE MARKETING" image={cdnLink + "/img/competitive-products_255x177.svg"} /></div>
            </div>
            <div className="row px-3 pb-5">
                <div className="col-lg-12 text-center standout shadow-callout bg-primary">
                    <div className="grid-col-2-auto-1fr">
                          <div className="grid-row-span-2">
                              <div className="standout-lightbulb-icon" />
                          </div>
                        <div>
                            <h3 className="text-white text-start font-primary-bold">Our Vision</h3>
                              <div className="grid-row-1 text-start text-white">The holding company’s vision is to be regionally recognized and respected as a leader in the insurance industry by consistently exceeding customer expectation through exceptional service, innovative marketing, competitive products and new programs, and leading edge automation.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br/><br/><br/>
        <div className="bg-light">
            <div className="container pb-5">
                <div className="d-flex justify-content-center align-content-center">
                      <img className="col-lg-4 col-10" style={{ marginTop: "-6rem" }} src={cdnLink + "/img/any-questions_430x295.svg"} alt="people sitting around Q A letters" />
                </div><br/>  
                <div className='row justify-content-center text-center px-4' >
                    <Link className="btn btn-primary rounded-pill col-lg-3" to="/ContactUs">Contact Us</Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutUs