import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/RidgeRunner', label: 'West Virginia National Auto Insurance' }
]

const CompanyRidgeRunner = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
      <>
        <title>West Virginia National Auto Insurance - Warrior Invictus</title>
        <Banner title="West Virginia National Auto Insurance" image="banner-company-sm" />
        <div className='container' style={{minHeight:"50rem"}}>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>
                <div className="row gap-5">
                    <div className="col-lg-8">
                          <img className="py-3" style={{width:"250px"}} alt="west virginia logo" src={cdnLink + "/img/logos/WV NEW LOGO.png"}/>                
                        <div className="h5Font">Insurance Company</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                            The mission of West Virginia National Auto Insurance Company is to provide a quality affordable insurance product distributed through a network of independent agents. By maintaining constant contact with our customers and agents and making it easy for them to do business with us, we believe that will enable us to meet our growth, retention, and profitability goals.                
                        </p>                        
                   </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <div className='h6Font font-secondary'>2011</div>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                330 Scott Avenue, Suite 2 <br/>
                                Morgantown, WV 26508
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Licensed States</div>
                            <p className='h6Font font-secondary'>VA WV</p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:8889862846">888-986-2846</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_RR_URL}>www.wvnational.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyRidgeRunner