import React, { useState } from "react";
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from "react-router-dom";
import $ from "jquery";
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PrivacyPolicy', label: 'Privacy Policy' }
]
const PrivacyPolicy = () => {
    const [DBResponse, setDBResponse] = useState(null);
    $.ajax({
        type: "POST",
        url: "/AppConfig/GetLegalPrivacyMessage",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (result) {
            setDBResponse(result.res.html);
        },
        failure: function (response) {
            console.log("Privacy Policy Page ERROR:" + response);
        },
    });
  return (
      <>
          <title>Privacy Policy - Warrior Invictus</title>

        <Banner title="Privacy Policy" image="banner-legal" />
        <div className="container pb-5">
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
              </Breadcrumb>
              <div dangerouslySetInnerHTML={{__html: DBResponse }}></div>
        </div>
    </>
  )
}

export default PrivacyPolicy