import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/WIN', label: 'Warrior Insurance Network' }
]

const CompanyWIN = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
      <>
        <title>Warrior Insurance Network - Warrior Invictus</title>

        <Banner title="Warrior Insurance Network" image="banner-company-sm" />
        <div className='container'>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>        
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" alt="warrior insurance logo" src={cdnLink + "/img/logos/Warrior Insurance Network 2C logo.png"} />                
                        <div className="h5Font">Marketing Powerhouse</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                            Warrior Insurance Network's (WIN) member companies include First Chicago Insurance Company (FCIC), United Security Insurance Company (USIC), West Virginia National Auto Insurance,Texas Ranger MGA (Texas Ranger) and Lonestar MGA (Lonestar). WIN is a marketing organization servicing a select group of insurance carriers and independent insurance agents which offer a diverse array of personal and commercial auto insurance products.
                            <br/><br/>
                            FCIC, whose roots trace back to 1920, has evolved into a multi-line insurance carrier, now offering Personal & Commercial Auto and General Liability insurance in response to the needs of the company's independent producers and customer base. In personal lines, FCIC has further responded to the changing exposures and needs of their independent producers by adding optional insurance coverages such as Contents Plus Renters, Roadside Assistance, and Transportation Network Coverage to enhance their personal lines auto offerings. USIC celebrated its 50th anniversary in 2023 and specializes in providing affordable personal lines auto insurance products with multiple discounts available, responsive claims handling and quality customer service. Texas Ranger MGA and Lonestar MGA expand WIN's presence to include the Texas market.
                        </p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <p className='h6Font font-secondary'>1920</p>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>      
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Licensed States</div>
                            <p className='h6Font font-secondary'>AR AZ CO GA IL IN IA<br/>
                                                                 KS LA MD MO NE NJ<br/>
                                                                 NV OH OK PA TN TX<br/>
                                                                 VA WV WI </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:8664008600">866-400-8600</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_WIN_URL}>www.warriorinsurancenetwork.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyWIN