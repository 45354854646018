import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/AtOncePrinting', label: 'At Once Printing' }
]

const CompanyAOP = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
      <>
        <title>At Once Printing - Warrior Invictus</title>
        <Banner title="At Once Printing" image="banner-company-sm" />
        <div className='container' style={{minHeight:"50rem"}}>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill h6Font no-wrap col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" alt="at once printing logo" src={cdnLink + "/img/logos/page_atonceprinting_leftaligned_188x72.svg"} />                
                        <div className="h5Font">Print Services</div>
                        <p className="pt-2 h6Font font-secondary text-dark">At Once Printing is a full-service print shop that provides design, print and direct mail services for small to medium-sized businesses in the area. Our shop has been in Bedford Park since 1996 and is staffed with over 30 years of professional printing and graphics experience.</p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <div className='h6Font font-secondary'>1996</div>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue, Ste. 190 <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:7085524400">708-552-4400</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_AOP_URL}>www.atonceprinting.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyAOP