import React,{useState} from 'react'
import { Banner } from '../components/Banner'
import Breadcrumb from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { Card } from '../components/Cards'

const items = [
  { to: '/', label: 'Home' },
  { to: '/PortfolioCompanies', label: 'Portfolio Companies' }
]


const PortfolioCompanies = () => { 
  const [Active, setActive] = useState("ALL");
  var cdnLink = "https://cdn.warriorinvictus.com";

  function Filter (e){
    e.preventDefault();

    let subject = e.target.value;
 
    setActive(subject);

      const items = document.querySelectorAll(`.ALL`);

      items.forEach((item) => {
          const category = item.classList[1];

          if (subject === category || subject === 'ALL') {
              item.classList.remove('d-none');
          } else {
              item.classList.add("d-none");
          }
      });
  }

  return (
      <>
      <title>Portfolio Companies - Warrior Invictus</title>
      <Banner title="Portfolio Companies" image="banner-portfolio"/>
      <div className="container">
        <Breadcrumb>
          {items.map(({to, label}) =>(           
          <Link key={to} to={to}>{label}</Link>
          ))}
        </Breadcrumb>
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <p>Read about the multiple entities that belong to the Warrior Invictus Holding Company Inc.</p>
          </div>
        </div><br/>
        <div className="grid-filter-container row px-2">
            <button className={`btn ${Active === 'ALL' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="ALL" onClick={(e) => {Filter(e)}}>All</button>
            <button className={`btn ${Active === 'AGENCY' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="AGENCY" onClick={(e) => {Filter(e)}}>Agencies</button>
            <button className={`btn ${Active === 'INSURANCE' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="INSURANCE" onClick={(e) => {Filter(e)}}>Insurance Carriers</button>
            <button className={`btn ${Active === 'SERVICE' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs `} value="SERVICE" onClick={(e) => {Filter(e)}}>Service Companies</button>
            <button className={`btn ${Active === 'MGA' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="MGA" onClick={(e) => {Filter(e)}}>Managing General Agency</button>
            <button className={`btn ${Active === 'MARKETING' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="MARKETING" onClick={(e) => {Filter(e)}}>Marketing</button>
            <button className={`btn ${Active === 'OTHER' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="OTHER" onClick={(e) => {Filter(e)}}>Other</button>
              </div>
        <div className="row">
            <div className="grid-companies gap-4 pt-3">
                <div className="ALL OTHER"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/atonceprinting_230x106.svg"} path="/AtOncePrinting"/></div>
                <div className="ALL INSURANCE"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/firstchicagoinsurancecompany_230x106.svg"} path="/FCIC"/></div>
                <div className="ALL AGENCY"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/insureonline_230x106.svg"} path="/InsureOnline"/></div>
                <div className="ALL OTHER"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/Katies Hallmark.jpg"} imgstyle="katies-img-dimension" path="/KatiesHallmark"/></div>
                <div className="ALL MGA"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/lonestarmga_230x106.svg"} path="/LonestarMGA"/></div>
                <div className="ALL MGA"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/texasrangermga_230x106.svg"} path="/TexasRangerMGA"/></div>
                <div className="ALL AGENCY"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/preferredhealthinsurancesolutions_230x106.svg"} path="/PHIS"/></div>
                <div className="ALL SERVICE"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/preferredriskadministrators_230x106.svg"} path="/PRA"/></div>
                <div className="ALL INSURANCE"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/WV NEW LOGO.png"} imgstyle="ridgerunner-img-dimension" path="/RidgeRunner"/></div>
                <div className="ALL INSURANCE"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/USIC Logo 4C.jpg"} path="/USIC"/></div>
                <div className="ALL MARKETING"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/Warrior Insurance Network 2C logo.png"} path="/WIN"/></div>
                <div className="ALL AGENCY"><Card size="h-100 px-4" type="card-link" image={cdnLink + "/img/logos/vitalityagency_230x106.svg"} path="/Vitality"/></div>
            </div>
        </div>
      </div>
    </>
  )
}

export default PortfolioCompanies