import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/PRA', label: 'Preferred Risk Administrators' }
]

const CompanyPRA = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
      <>
        <title>Preferred Risk Administrators - Warrior Invictus</title>
        <Banner title="Preferred Risk Administrators" image="banner-company-sm" />
        <div className='container' style={{minHeight:"50rem"}}>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" alt="preferred risk admin logo" src={cdnLink + "/img/logos/preferredriskadministrators_leftaligned_130x80.svg"}/>                
                        <div className="h5Font">Insurance Service Company</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                            Preferred Risk Administrators offers a wide range of TPA services. Preferred Risk Administrators leading staff is experienced in the managed care, health insurance, ancillary products and self‐funded industry. What truly distinguishes them from the competition is the fact that thy are nimble, which is extremely important in the fast changing world of health care and health insurance. They have a dedicated, decisive team that provides solutions to meet your individual needs. They have commitment to quality and customer service is unparalleled in the industry. They have the flexibility to implement customized plans with robust PPO networks in a paperless, automated environment and provide the necessary reporting that allows our clients to closely monitor activities and results. 
                            <br/><br/>
                            At Preferred Risk Administrators, they have business solutions that will provide you with the cost savings and services you need, and the quality benefits administration that your employees desire!
                        </p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <div className='h6Font font-secondary'>1980</div>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:7084756100">708-475-6100</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_PRA_URL}>www.preferredriskadmin.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyPRA