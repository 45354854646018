import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/Vitality', label: 'Wellness Invictus' }
]

const CompanyVitality = () => {
  var cdnLink = "https://cdn.warriorinvictus.com";
  return (
      <>
        <title>Wellness Invictus - Warrior Invictus</title>
        <Banner title="Wellness Invictus" image="banner-company-sm" />
        <div className='container' style={{minHeight:"50rem"}}>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" alt="vitality logo" src={cdnLink + "/img/logos/vitalityagency_leftaligned_136x72.svg"}/>                
                        <div className="h5Font">Agency</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                        Technology & Account Management Distribution Partner for Vitality Group Corporate Wellness Platform to Mid-Market Employer Groups in the US.                        </p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <div className='h6Font font-secondary'>1980</div>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:6302057083">630-205-7083</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_VITALITY_URL}>www.wellnessinvictus.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyVitality